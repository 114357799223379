import React from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getRecreationalAlternateTerm } from "redux/selectors/complianceRules";
import { getCoordinates } from "redux/selectors/location";
import buildDispensaryGoogleMapsUrl from "utils/dispensary/buildDispensaryGoogleMapsUrl";
import getDistanceFromDispensary from "utils/dispensary/getDistanceFromDispensary";
import { dispensaryType } from "utils/dispensaryUtils";

import StarRating from "components/botanic/StarRating";
import Actions from "components/Dispensary/DispensaryHeader/Actions";
import BusinessHours from "components/Dispensary/DispensaryHeader/BusinessHours";
import PhotoGallery from "components/Dispensary/DispensaryHeader/PhotoGallery";
import DispensaryHeaderFulfillment from "components/Dispensary/DispensaryHeaderFulfillment";
import FollowButton from "components/Dispensary/FollowDispensaryButton";
import DispensaryPixelTracking from "components/DispensaryPixelTracking";
import StorefrontIcon from "components/Icons/storefront.svg";
import Image from "components/Image";
import LeaflyListBadge from "components/LeaflyListBadge";
import UberEgress from "components/UberEgress";

import ClaimInfoListing from "../ClaimInfoListing";

const DispensaryHeader: React.FC<{
  dispensary: Dispensary;
  isMainPage?: boolean;
  reviewCount?: number;
}> = ({ dispensary, isMainPage, reviewCount }) => {
  const countryCode = useDomainCountryCode();
  const { publishEvent } = useEventTracker();

  const coordinates = useSelector(getCoordinates);
  const recreationalTermOverride = useSelector(getRecreationalAlternateTerm);

  const {
    city,
    featureTier,
    flags,
    hasDeliveryEnabled,
    id,
    logoUrl,
    name,
    pickupEnabled,
    retailType,
    roundedRating,
    slug,
    state,
    tags,
    uberEatsStoreUrl,
  } = dispensary;

  const googleMapsDirectionsUrl =
    (!hasDeliveryEnabled || pickupEnabled) &&
    buildDispensaryGoogleMapsUrl(dispensary, true);

  const localizedDistance = getDistanceFromDispensary(
    countryCode,
    dispensary,
    coordinates,
  );

  const dispensaryTypeString = dispensaryType({
    dispensaryTags: tags,
    isCA: countryCode === "CA",
    recreationalTerm: recreationalTermOverride,
  });

  const isInfoTier = featureTier === "info";

  const totalReviewCount = reviewCount || dispensary.reviewCount;

  return (
    <>
      {state && <DispensaryPixelTracking dispensaryState={state} />}
      <div className="container">
        {!isInfoTier && isMainPage && (
          <PhotoGallery dispensary={dispensary} className="mb-lg" />
        )}
        <div
          className={classnames("row justify-between", {
            "mt-6": isInfoTier || !isMainPage,
          })}
        >
          <div className="col lg:col-5 flex flex-col gap-4 relative mb-lg">
            <FollowButton
              className="absolute top-0 right-0"
              dispensaryName={dispensary.name}
              dispensaryId={dispensary.id}
            />
            {isInfoTier ? (
              <div className="h-[50px] w-[50px] flex items-center justify-center bg-leafly-white rounded p-2">
                <StorefrontIcon className="opacity-20" width="30" height="30" />
              </div>
            ) : (
              <Image
                src={logoUrl}
                sizes={[50, null, null, 75, null, 50]}
                alt={`Logo for ${name}`}
                className="rounded overflow-hidden h-[50px] w-[50px] lg:h-[75px] lg:w-[75px]"
                data-testid="dispensary-header__logo"
                preload
                disableLazyLoad
              />
            )}
            <div className="flex items-start flex-col gap-1">
              <div className="flex gap-2">
                <div className="uppercase text-xs font-bold border border-light-grey rounded px-sm">
                  {retailType}
                </div>
                {dispensaryTypeString && (
                  <div className="uppercase text-xs font-bold border border-light-grey rounded px-sm">
                    {dispensaryTypeString}
                  </div>
                )}
              </div>
              <h1 className="heading--m">{name}</h1>
              {flags?.includes("leaflyListBadge") && (
                <LeaflyListBadge dispensaryId={id} />
              )}
              <div className="flex">
                <div className="text-xs">{`${city}, ${state}`}</div>
                <span
                  className={`mx-sm ${
                    totalReviewCount > 0 && "after:content-['•']"
                  }`}
                />
                <StarRating
                  rating={roundedRating}
                  ratingCount={totalReviewCount}
                  showReviewsText={true}
                  starLink={
                    isMainPage
                      ? "#reviews-overview"
                      : `/dispensary-info/${slug}/reviews`
                  }
                />
              </div>
              <div className="flex w-full justify-between items-center">
                {googleMapsDirectionsUrl ? (
                  <a
                    className="underline text-xs font-bold"
                    href={googleMapsDirectionsUrl}
                    onClick={() => {
                      publishEvent({
                        action: Action.click,
                        category: Category.dispensaryHeader,
                        dispensaryId: id,
                        label: "miles away get directions",
                      });
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {localizedDistance.full}
                  </a>
                ) : (
                  <div
                    className="text-xs"
                    data-testid="dispensary-header__distance"
                  >
                    {localizedDistance.full}
                  </div>
                )}
                <BusinessHours dispensary={dispensary} />
              </div>
              {isInfoTier && (
                <ClaimInfoListing dispensaryFlags={flags} dispensaryId={id} />
              )}
            </div>
            {isMainPage && <Actions dispensary={dispensary} />}
          </div>
          <div className="col lg:col-5">
            <DispensaryHeaderFulfillment dispensary={dispensary} />
            {uberEatsStoreUrl && (
              <div className="hidden md:flex flex-col gap-md py-xs">
                <span className="flex self-center text-sm text-grey font-extrabold">
                  – OR –
                </span>
                <UberEgress url={uberEatsStoreUrl} dispensaryId={id} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DispensaryHeader;
